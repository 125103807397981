@import url('https://fonts.googleapis.com/css?family=Poppins');

@import './variables.scss';



html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

.mer-fill {
  min-height: 100%;
  height: 100%;
}


@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans Mono';
  src: url('./assets/fonts/MessinaSansMono-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
}

* {
  // font-size: 14px !important;
  font-family: 'Messina Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  /* font-family: 'Futura PT'; */
}

// * {
//   // font-size: 14px !important;
//   // font-family: 'Poppins' !important;
//   font-family: 'Futura PT' !important;
// }

//   #mer-html-string *{
//     font-size: auto !important;
//     font-family: auto !important;
//   }


// background colors

.mer-bg-white {
  background: #ffffff;
}

.mer-color-primary {
  color: $acc-content-primary !important;
}

.mer-border-red {
  border: 2px solid red;
}

.mer-border-primary {
  border: 2px solid #5655A8;
}

.mer-bg-white-field {
  background: #ffffff;
  border-radius: 4px !important;
}

.mer-position-fixed {
  position: fixed !important;
}

.mer-position-relative {
  position: relative !important;
}


.mer-text-decoration-underline {
  text-decoration: underline !important;
}

.mer-text-decoration-none {
  text-decoration: none !important;
}

.mer-hyper-link-color {
  color: $mer-color2 !important;
}



@for $i from 1 to 15000 {
  .mer-z-index-#{$i} {
    z-index: $i !important;
  }

  .mer-font-weight-#{$i} {
    font-weight: $i !important;
  }
}

@for $i from 0 to 101 {
  .mer-width-percentage-#{$i} {
    width: #{$i}#{"%"} !important;
  }

  .mer-height-percentage-#{$i} {
    height: #{$i}#{"%"} !important;
  }

  .mer-max-width-percentage-#{$i} {
    width: #{$i}#{"%"} !important;
  }
}



@for $i from -100 to 1000 {

  // Margin classes
  .mer-margin-#{$i} {
    margin: $i + px !important;
  }

  .mer-margin-right-#{$i} {
    margin-right: $i + px !important;
  }

  .mer-margin-left-#{$i} {
    margin-left: $i + px !important;
  }

  .mer-margin-top-#{$i} {
    margin-top: $i + px !important;
  }

  .mer-margin-bottom-#{$i} {
    margin-bottom: $i + px !important;
  }

  // Padding classes
  .mer-padding-#{$i} {
    padding: $i + px !important;
  }

  .mer-padding-right-#{$i} {
    padding-right: $i + px !important;
  }

  .mer-padding-left-#{$i} {
    padding-left: $i + px !important;
  }

  .mer-padding-top-#{$i} {
    padding-top: $i + px !important;
  }

  .mer-padding-bottom-#{$i} {
    padding-bottom: $i + px !important;
  }

  // Font size classes
  .mer-font-size-#{$i} {
    font-size: $i + px !important;
  }

  //width classes
  .mer-width-#{$i} {
    width: $i + px !important;
  }

  .mer-min-width-#{$i} {
    min-width: $i + px !important;
  }

  .mer-max-width-#{$i} {
    max-width: $i + px !important;
  }

  //height classes

  .mer-height-#{$i} {
    height: $i + px !important;
  }

  .mer-min-height-#{$i} {
    min-height: $i + px !important;
  }

  .mer-max-height-#{$i} {
    max-height: $i + px !important;
  }



  .mer-border-radius-#{$i} {
    border-radius: $i + px !important;
  }


  .mer-line-height-#{$i} {
    line-height: $i + px !important;
  }

  .mer-rounded-#{$i} {
    border-radius: $i + px !important;
  }

  .mer-border-width-#{$i} {
    border-width: $i + px !important;
  }

  .mer-position-right-#{$i} {
    right: $i + px !important;
  }

  .mer-position-bottom-#{$i} {
    bottom: $i + px !important;
  }


  // For mobile responsiveness 
  @media screen and (max-width: 576px) {
    .mer-mobile-width-percentage-#{$i} {
      width: #{$i}#{"%"} !important;
    }

    .mer-mobile-margin-right-#{$i} {
      margin-right: #{$i}+px !important;
    }

    .mer-mobile-margin-left-#{$i} {
      margin-left: #{$i}+px !important;
    }

    .mer-mobile-padding-right-#{$i} {
      padding-right: #{$i}+px !important;
    }

    .mer-mobile-margin-bottom-#{$i} {
      margin-bottom: #{$i}+px !important;
    }
  }
}


.desk-alerts-header {
  background-color: #ffffff !important;
  color: #1F242F !important;
}

.mer-solid-border {
  border-style: solid;
}

.mer-color-red {
  color: #d32f2f
}

.mer-logincard {
  background: #FFFFFF !important;
  border-radius: 16px !important;

}

.mer-error {
  color: #d32f2f;
  font-size: 12px !important;
  padding: 0 5px;
}


.mer-overflow {
  overflow: auto !important;
}

.mer-overflow-auto {
  overflow: auto !important;
}

.mer-overflow-hidden {
  overflow: hidden !important;
}

.mer-overflow-x-hidden {
  overflow-x: hidden !important;
}

.mer-overflow-y-auto {
  overflow-y: auto !important;
}

.mer-overflow-x-auto {
  overflow-x: auto !important;
}

.mer-cursor-pointer {
  cursor: pointer;
}

.mer-selectdisabled {
  color: rgb(158, 158, 158);
}

.recipients-view-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 60vw;
  min-width: 60vw;
  max-height: 67vh;
  min-height: 50vh;
  margin: 0px;
}

.mer-statistics-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 50vw !important;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root {
  background: $acc-lavender;
  border-radius: 16px;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: black;
  font-weight: lighter;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: $acc-content-primary;
  color: #FFFFFF
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root {
  background: #FFFFFF;
  border-radius: 16px;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
  color: #d32f2f;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: #d32f2f;
  font-weight: lighter;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: #d32f2f;
  color: white !important;
}

.sun-editor .se-resizing-bar .se-navigation {
  display: none !important;
}

.mer-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
}

.mer-html-text-overflow {
  div>div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 20vw;
  }
}

.mer-html-text-overflow-width {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 20vw;
}


.mer-history-html-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 260px;
}

.mer-font-bold {
  font-weight: 600 !important;
}

.mer-alert-border {
  border: 6px solid #333333;
}

.mer-secondary-bg {
  background: #333333 !important;
}

.mer-help-text {
  color: #929094;
  font-weight: 500 !important;
}

.mer-menu-subgroup-check-box {
  transform: scale(0.6) !important;
  margin-right: -10px !important;
}

// .checkbox-label > .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
//   font-size: 12px !important;
// }
.mer-color-light-primary {
  // color:#006590 !important;
  color: $acc-content-primary !important;
}

.mer-subject-border-bottom {
  // border-bottom: 2px solid #E2DFFF;
  border-bottom: 2px solid #818995;
}

.mer-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@for $i from -100 to 450 {

  .mer-styletheme-table-sticky-header-right-#{$i} {
    position: sticky;
    right: $i + px !important;
    z-index: 101 !important;
    background: #E2DFFF !important;
  }



  .mer-styletheme-table-sticky-header-left-#{$i} {
    position: sticky;
    left: $i + px !important;
    z-index: 101 !important;
    // background: #E2DFFF !important;
  }

  .mer-styletheme-table-sticky-body-right-#{$i} {
    position: sticky;
    right: $i + px !important;
    z-index: 100 !important;
    background: $acc-container-primary;
    // background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;
  }

  .mer-styletheme-table-sticky-body-right-#{$i}:hover {
    background-color: #eeeef2 !important;
  }

  .mer-styletheme-table-sticky-body-left-#{$i} {
    position: sticky;
    left: $i + px !important;
    z-index: 100 !important;
    background: $acc-container-primary;
    // background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;

  }

  .MuiTableBody-root:hover {
    background-color: #eeeef2 !important;
  }
}

.select_divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.clear_field .MuiAutocomplete-endAdornment {
  top: 0px;
}

.mer-access-denied-card {
  background-color: #FFDAD6;
}

.volume-slider>.MuiSlider-thumb {
  width: 20px !important;
  height: 20px !important;
}

.MuiAutocomplete-endAdornment {
  transform: none !important;
}



.mer-tab-color-selected {
  color: #1F242F;
  font-weight: 700;
  opacity: 1
}

.mer-tab-color-unselected {
  color: #1F242F;
  font-weight: 600;
  opacity: 1
}

.mer-bg-gray {
  background-color: #e7e0ec;
  border-radius: 6px;
  padding: 4px
}